<template>
    <div>
        <el-main>
            <!-- 表单 -->
            <el-form class="el-form-search" label-width="140px">
                <el-form-item label="活动分类：">
                    <el-select v-model="searchForm.cate_id" size="small" clearable>
                        <el-option v-for="item in classify" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="活动标题：">
                    <el-input size="small" placeholder="请输入活动标题" v-model="searchForm.title"></el-input>
                </el-form-item>
                <el-form-item label="活动区域：">
                    <el-cascader v-model="searchForm.city_id" :options="allAreaList"
                        :props="{ value: 'id', label: 'name', children: '_child', emitPath: false }"
                        clearable></el-cascader>
                </el-form-item>
                <el-form-item label="审核状态：">
                    <el-select v-model="searchForm.status" size="small" placeholder="请选择" clearable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="审核中" :value="0"></el-option>
                        <el-option label="已通过（待支付）" :value="1"></el-option>
                        <el-option label="已驳回" :value="-1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label=" " label-width="25px">
                    <el-button type="primary" size="small" @click="search">搜索</el-button>
                    <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
                </el-form-item>
            </el-form>
            <!-- 列表 -->
            <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="title" label="活动标题" min-width="160" align="center"></el-table-column>
                <el-table-column prop="merchant_mobile" label="活动封面" width="110" align="center">
                    <template v-slot="{ row }">
                        <el-image :src="row.image" alt="" style="width: 60px;height: 60px;"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="cate_name" label="活动分类" min-width="110" align="center"></el-table-column>
                <el-table-column prop="integral" label="奖励积分/人" min-width="130" align="center"></el-table-column>
                <el-table-column prop="num" label="预估人数" min-width="100" align="center">
                    <template v-slot="{ row }">{{ row.num || '--' }}</template>
                </el-table-column>
                <el-table-column prop="yg_integral" label="额外奖励积分" min-width="130" align="center">
                    <template v-slot="{ row }">{{ row.yg_integral || '--' }}</template>
                </el-table-column>
                <el-table-column label="活动时间" min-width="160" align="center">
                    <template v-slot="{ row }">{{ getDateformat(row.start_time) }}-{{ getDateformat(row.end_time)
                    }}</template>
                </el-table-column>
                <el-table-column prop="merchant_mobile" label="活动区域" min-width="150" align="center">
                    <template v-slot="{ row }">{{ row.province_name ? row.province_name + row.city_name : '--' }}</template>
                </el-table-column>
                <el-table-column prop="address" label="活动地址" min-width="160" align="center"></el-table-column>
                <el-table-column prop="merchant_mobile" label="审核状态" min-width="100" align="center">
                    <template v-slot="{ row }">{{ row.status == 0 ? '审核中 ' : row.status == 1 ? '待支付' : '已驳回' }}</template>
                </el-table-column>

                <el-table-column label="操作" align="center" min-width="160" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" @click="handleDetail(scope.row.id)" size="small">活动详情</el-button>
                        <el-button v-if="scope.row.status == -1" type="text" @click="handleReason(scope.row.reason)"
                            size="small">驳回原因</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-dialog title="活动详情" :visible.sync="dialogVisible" width="800px">
                <el-form label-width="140px">
                    <el-form-item label="商家名称：">
                        <div>{{ info.store_name || '--' }}</div>
                    </el-form-item>
                    <el-form-item label="商家电话：">
                        <div>{{ info.merchant_mobile || '--' }}</div>
                    </el-form-item>
                    <el-form-item label="活动分类：">
                        <div>{{ info.cate_name || '--' }}</div>
                    </el-form-item>
                    <el-form-item label="活动标题：">
                        <div>{{ info.title || '--' }}</div>
                    </el-form-item>
                    <el-form-item label="活动区域：">
                        <div>{{ info.province_name ? info.province_name + info.city_name : '--' }}</div>
                    </el-form-item>
                    <el-form-item label="活动地址：">
                        <div>{{ info.address || '--' }}</div>
                    </el-form-item>
                    <el-form-item label="活动时间：">
                        <div>{{ getDateformat(info.start_time) }}至{{ getDateformat(info.end_time) }}</div>
                    </el-form-item>
                    <el-form-item label="奖励积分/人：">
                        <div>{{ info.integral || '--' }}</div>
                    </el-form-item>
                    <el-form-item label="预估人数：">
                        <div>{{ info.num || '--' }}</div>
                    </el-form-item>
                    <el-form-item label="额外奖励积分：">
                        <div>{{ info.yg_integral || '--' }}</div>
                    </el-form-item>
                    <el-form-item label="支付金额（元）：">
                        <div>{{ info.price || '--' }}</div>
                    </el-form-item>
                    <el-form-item label="活动图片：">
                        <el-image :src="info.image" style="width:100px;height: 100px;"></el-image>
                    </el-form-item>
                    <el-form-item label="活动详情：">
                        <div v-html="info.detail" class="detail"></div>
                    </el-form-item>
                </el-form>
            </el-dialog>
            <el-dialog title="驳回原因" :visible.sync="dialogReason" width="30%">
                <div>{{ reason }}</div>
            </el-dialog>
            <!-- 翻页 -->
            <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
            </Paging>
        </el-main>
    </div>
</template>
  
<script>

import _ from 'lodash';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';

export default {
    components: {
        Paging,
    },
    data () {
        return {
            list: [],
            total_number: 0,
            searchForm: {
                page: 1,
                rows: 10,
                cate_id: '',
                title: '',
                name: '',
                mobile: '',
                status: '',
                city_id: '',
            },

            allAreaList: [],
            classify: [],
            dialogVisible: false,
            info: '',
            dialogAudit: false,
            audit_form: {
                id: '',
                status: 1,
                is_pay: 1,
                price: '',
                reason: ''
            },
            reason: '',
            dialogReason: false
        };
    },
    created () {
        this.getList();
        this.getClassify()
        this.getArea()
    },
    methods: {
        // 获取省市区信息
        getArea () {
            this.$axios
                .get(this.$api.set.areaList, {
                    tree: 1,
                    level: 1,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.allAreaList = res.result.list;
                    }
                });
        },
        getClassify () {
            this.$axios.post(this.$api.marketing.ActivityCateList, {
                page: 1,
                rows: 100
            }).then(res => {
                if (res.code == 0) {
                    this.classify = res.result.list;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        search () {
            this.searchForm.page = 1;
            this.getList();
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                cate_id: '',
                title: '',
                name: '',
                mobile: '',
                status: '',
                city_id: '',
            };
            this.getList();
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        // 获取列表
        getList () {
            let searchForm = { ...this.searchForm }
            this.$axios.post(this.$api.marketing.ActivityAuditList, searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleDetail (id) {
            this.$axios.post(this.$api.marketing.ActivityInfo, { id }).then(res => {
                if (res.code == 0) {
                    this.info = res.result
                    this.dialogVisible = true
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleAudit (id, status) {
            this.audit_form.id = id
            this.audit_form.status = status
            this.dialogAudit = true
        },
        confirmAudit () {
            this.$axios.post(this.$api.repair.marketing.ActivityAudit, this.audit_form).then(res => {
                if (res.code == 0) {
                    this.$message.success('审核成功');
                    this.getList();
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleReason (reason) {
            this.reason = reason
            this.dialogReason = true
        }
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    background: #fff;
}

.detail {
    width: 600px;

    /deep/img {
        width: 100%;
        height: auto;
    }
}
</style>
  